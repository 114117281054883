import React from "react"
import Layout from "../containers/Layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSadCry } from "@fortawesome/free-solid-svg-icons"
import { FormattedMessage } from "gatsby-plugin-intl"
import { ColoredLink } from "../components/Buttons"

const NotFoundPage = () => (
  <Layout notFound>
    <SEO title="404: Not found" />
    <section className="container mx-auto text-center h-full mb-64 pt-16">
      <h2 className="text-5xl text-gray-500">
        <FontAwesomeIcon icon={faSadCry} />
      </h2>
      <h1 className="text-gray-500">
        <FormattedMessage id="notFound.title" />
      </h1>
      <p className="mb-20 text-gray-500">
        <FormattedMessage id="notFound.text" /> <br />
        <ColoredLink to="/">
          <FormattedMessage id="notFound.goBlog" />
        </ColoredLink>
        <FormattedMessage id="notFound.whoSay" />
        <ColoredLink to="/knowme">
          {" "}
          <FormattedMessage id="notFound.knowAuthor" />
        </ColoredLink>
      </p>
    </section>
  </Layout>
)

export default NotFoundPage
